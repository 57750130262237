import { css } from '@emotion/core'
import React from 'react'

import {
  Container,
  Layout,
  Section,
  SEO,
  SlantedText,
  TextLine,
  TextLineBlock
} from '../components'
import {
  getHeadingColor,
  linkTextLineStyle,
  makeContentStyle,
  srOnly,
  textColumnStyle
} from '../styles'
import makePath from '../utils/makePath'

const style = {
  link: css`
    padding: 0 !important;
  `,
  content: css`
    text-align: center;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
  `,
  info: css`
    margin-top: 10vh;
    font-size: 0.75em;
  `
}
export const NotFoundPage = (props: any) => {
  const currPath = props.location.pathname
  const pageColor = 'red'
  const colors1 = getHeadingColor(pageColor, 0)
  const colors2 = getHeadingColor(pageColor, 1)
  const colors3 = getHeadingColor(pageColor, 2)
  const colors4 = getHeadingColor(pageColor, 3)
  const title = 'Fehler 404: Diese Seite gibt es leider nicht!'
  return (
    <Layout currPath={currPath} pageColor={pageColor}>
      <SEO title={title} />
      <Section color={pageColor} index={0} renderBg={true}>
        <Container>
          <div css={style.content}>
            <h1 css={srOnly}>{title}</h1>
            <TextLineBlock>
              <TextLine
                xOffset={-10}
                size={5}
                background={colors1[1]}
                color={colors1[0]}
                fontFamily="druk text wide"
              >
                §404
              </TextLine>
              <TextLine
                fontFamily="druk text wide"
                size={2.1}
                background={colors2[1]}
                color={colors2[0]}
              >
                abschaffen!*
              </TextLine>
            </TextLineBlock>
            <a href={makePath('mitmachen')} css={[style.link, linkTextLineStyle]}>
              <TextLineBlock>
                <TextLine
                  size={1.2}
                  background={colors3[1]}
                  color={colors3[0]}
                  fontFamily="druk text wide"
                  xOffset={10}
                >
                  Jetzt Rote Hilfe
                </TextLine>
                <TextLine
                  fontFamily="druk text wide"
                  size={1.33}
                  background={colors4[1]}
                  color={colors4[0]}
                  xOffset={20}
                >
                  Mitglied werden!
                </TextLine>
              </TextLineBlock>
            </a>
            <div css={[textColumnStyle, makeContentStyle(pageColor), style.info]}>
              <SlantedText>*) 404 ist ein Fehler-Code für »Seite nicht gefunden«</SlantedText>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
